.page-takeoff {
    padding: 0.5rem 0;
}

.page-takeoff__params-table {
    width: 100%;
}

.page-takeoff__params-table tr td:last-of-type {
    text-align: right;
}
