.page-weather {
    padding: 8px;
}

.page-weather .header {
    text-align: center;
}

.page-weather__params-table {
    width: 100%;
}

.page-weather__params-table tr td:last-of-type {
    text-align: right;
}
