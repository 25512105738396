.navbar {
    height: 64px;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #ddd;
    background: #f0f0f0;
    margin: auto 0 0;
}

.navbar-button {
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex: 1;
}

.navbar-button--selected {
    background: aliceblue;
}

.navbar-button .svg-icon {
    fill: #000;
    margin-bottom: 4px;
}
