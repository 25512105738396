.info-list__item {
    display: flex;
    align-items: center;
    height: 1.5rem;
    line-height: 100%;
    margin: 8px 0;
}

.info-list__item .svg-icon {
    fill: #000;
    margin-right: 4px;
    min-width: 1rem;
}
