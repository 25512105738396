.checklist-item {
    display: flex;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000;
    padding: 0.75rem 0 0.75rem;
}

.checklist-item__input {
    display: inline-flex;
    align-items: center;
}

.checklist-item__input input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.checklist-item__control {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px;
    border: 2px solid red;
    display: inline-block;
    background: transparent;
}

.checklist-item__control svg {
    display: none;
    width: 1.2rem;
}

.checklist-item__texts {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 0.5rem;
    user-select: none;
}

/* checked */

.checklist-item__input input:checked + .checklist-item__control svg {
    display: block;
    stroke: #000;
}

.checklist-item__input input:checked + .checklist-item__control {
    border-color: #ddd;
    background: #fff;
}
