.input__label {
    color: var(--color-primary-100);
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.31rem;
}

.input__helper {
    color: var(--color-gray-200);
    font-size: 0.75rem;
    margin-top: 0.31rem;
}

.input__wrapper {
    background: var(--color-tertiary);
    border: 1px solid var(--color-tertiary-400);
    border-radius: 3px;
    display: flex;
    width: 100%;
}

.input__wrapper:hover {
    border-color: var(--color-tertiary-300);
}

.input__icon-box {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0 0.4rem 0 0.63rem;
}

.input__icon-box svg {
    fill: var(--color-gray-300);
}

.input__icon-cursor {
    cursor: pointer;
}

.input__itself {
    color: var(--color-gray-50);
    background: transparent;
    outline: none;
    border: none;
    height: 2.5rem;
    padding: 0 0.63rem;
    width: 100%;
    font-size: 1.2rem;
}

.input__itself--no-left {
    padding-left: 0;
}

.input__itself--no-right {
    padding-right: 0;
}

.input__itself::placeholder {
    color: var(--color-gray-300);
}

.input__wrapper:focus-within {
    border-color: var(--color-primary);
    background: var(--color-tertiary-600);
}

/* Disabled */

.input--disabled {
    color: var(--color-gray-600);
}

.input--disabled .input__label {
    color: var(--color-gray-600);
}

.input--disabled .input__helper {
    color: var(--color-gray-600);
}

.input--disabled .input__wrapper {
    background: var(--color-tertiary-800);
    border-color: var(--color-tertiary-700);
}

.input--disabled .input__wrapper:hover {
    border-color: var(--color-tertiary-700);
}

.input--disabled svg {
    fill: var(--color-gray-600);
}

/* Error */

.input--error .input__helper {
    color: var(--color-error-600);
}

.input--error .input__wrapper {
    border-color: var(--color-error-600);
}

.input--error svg {
    fill: var(--color-error-600);
}

/* Success */

.input--success .input__helper {
    color: var(--color-success);
}

.input--success .input__wrapper {
    border-color: var(--color-success);
}

.input--success svg {
    fill: var(--color-success);
}
