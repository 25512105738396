.topbar {
    height: 48px;
    border-bottom: 1px solid #ddd;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.topbar select {
    margin-left: 8px;
}

.topbar select {
    font-size: 16px;
}
