.checklist .section-name {
    border-top: 1px dashed #888;
    margin: 1rem 0 0.5rem;
    padding-top: 1rem;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    color: #666;
}

.checklist select {
    background: #f2f3f4;
    border: 1px solid #ddd;
    padding: 0.3rem 0.5rem;
    border-radius: 3px;
    font-size: 1.4rem;
}

.checklist select.attention {
    color: red;
}

.checklist__select-row {
    display: flex;
    font-size: 1.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
}

.checklist__stats {
    border-top: 1px dashed #888;
    margin-top: 1rem;
    padding-top: 1rem;
}

.checklist__stats-bad {
    color: red;
    font-size: 1.5rem;
}

.checklist__stats-good {
    font-size: 1.5rem;
}
