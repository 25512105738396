html {
    /* Main colors */
    --color-primary: #000;
    --color-secondary: #f7ecdf;
    --color-tertiary: #fff;
    --color-warning: #FFC043;
    --color-success: #00AA60;
    --color-error: #F33430;
    --color-purple: #9E4894;
    --color-orange: #E46D01;

    /* Tints */
    --color-primary-100: #000;
    --color-secondary-100: #bbb5aa;
    --color-secondary-200: #d0c9bd;
    --color-secondary-300: #ded4ca;
    --color-tertiary-100: #fff;
    --color-tertiary-200: #fff;
    --color-tertiary-300: #fff;
    --color-primary-400: #000;
    --color-secondary-400: #e6dfd7;
    --color-tertiary-400: #aca69d;
    --color-warning-400: #FFCD69;
    --color-success-400: #1AB370;
    --color-error-400: #F44845;
    --color-purple-400: #A85A9F;
    --color-orange-400: #E77C1A;

    /* Shades */
    --color-primary-600: #000;
    --color-primary-700: #000;
    --color-secondary-600: #f8eee2;
    --color-tertiary-600: #fff;
    --color-tertiary-700: #fff;
    --color-tertiary-800: #fff;
    --color-warning-600: #E6AD3C;
    --color-success-600: #009956;
    --color-error-600: #DB2F2B;
    --color-error-800: #7A1A18;

    /* Greys */
    --color-gray-50:  #000000;
    --color-gray-100: #191919;
    --color-gray-200: #323232;
    --color-gray-300: #4C4C4C;
    --color-gray-400: #656565;
    --color-gray-500: #7E7E7E;
    --color-gray-600: #979797;
    --color-gray-700: #B0B0B0;
    --color-gray-800: #CACACA;
    --color-gray-900: #E3E3E3;
    --color-black: #FCFCFC;

    /* Whites */
    --color-white: #FFFFFF;
    --color-white-5: rgba(255, 255, 255, 0.05);
    --color-white-10: rgba(255, 255, 255, 0.1);
    --color-white-20: rgba(255, 255, 255, 0.2);
}
